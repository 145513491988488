import * as React from "react";
import { navigate } from "gatsby";
import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Container, Box, Grid, Paper, Typography, Divider, Button, Chip, Breadcrumbs } from "@mui/material";

const SubcategoryList = ({ subcategories }) => {
    const { language, languages, defaultLanguage, originalPath, path, siteUrl } = useI18next();
    const { t } = useTranslation();

    let sortedSubcategories = subcategories
        .map(subcategory => {
            return {
                ...subcategory,
                articles: subcategory.articles.sort(function (a, b) {
                    return b.order - a.order;
                }),
            };
        })
        .sort(function (a, b) {
            return b.order - a.order;
        });

    let urlLanguageCode = "";

    if (language != defaultLanguage) {
        urlLanguageCode = `${language}/`;
    }

    const openCategory = slug => event => {
        window.open(`${siteUrl}/${urlLanguageCode}category/${slug}`, "_self");
        // navigate(`${siteUrl}${language}/category/${slug}`, { replace: true })
    };

    const openArticle = slug => event => {
        window.open(`${siteUrl}/${urlLanguageCode}${slug}`, "_self");
        // navigate(`${siteUrl}${language}/subcategory/${slug}`, { replace: true })
    };

    return (
        <Container disableGutters maxWidth={false}>
            <Box display={"flex"} alignContent={"center"} flexDirection={"column"} gap={8}>
                {sortedSubcategories.map(subcategory => (
                    <Grid
                        style={{
                            boxShadow: "0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04)",
                            borderRadius: 8,
                            minHeight: 200,
                        }}
                        key={subcategory.id}
                        container
                    >
                        <Grid item xs={12} p={2}>
                            <Box>
                                {subcategory.articles.map(article => (
                                    <Box
                                        key={article.id}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        p={1}
                                        sx={{ cursor: "pointer" }}
                                        component={"span"}
                                        fullWidth
                                        onClick={openArticle(article.slug)}
                                    >
                                        <Typography style={{ fontSize: 16 }}>{article.title}</Typography>
                                        <ArrowForwardIosIcon />
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Container>
    );
};

export default SubcategoryList;
