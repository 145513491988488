import React from "react";
import { graphql } from "gatsby";
import Header from "../components/Header";
import LeftSideBar from "../components/LeftSideBar";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next";
import Moment from "react-moment";
import theme from "../config/theme";
import "../styles/layout.css";
import SubcategoryList from "../components/SubcategoryList";

import { Container, Box, Card, CardContent, Breadcrumbs, Typography } from "@mui/material";

const SubcategoryTemplate = props => {
    const { language, languages, defaultLanguage, originalPath, path, siteUrl } = useI18next();
    const { data } = props;

    let urlLanguageCode = "";

    if (language != defaultLanguage) {
        urlLanguageCode = `${language}/`;
    }

    const openCategory = slug => event => {
        window.open(`${siteUrl}/${urlLanguageCode}category/${slug}`, "_self");
        // navigate(`${siteUrl}${language}/category/${slug}`, { replace: true })
    };

    console.log(props);
    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters maxWidth={false}>
                <Header props={props} showMenu={true} />
                <Box display={"flex"}>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <LeftSideBar subcategories={props.data.subcategories ? props.data.subcategories.edges : []} slug={props.pageContext.slug} variant={"permanent"} isOpen={true} />
                    </Box>
                    {data.subcategory && (
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Box component={"span"} sx={{ cursor: "pointer" }} onClick={openCategory(data.subcategory.category.slug)}>
                                    <Typography style={{ fontSize: 14 }} color="text.primary">
                                        {" "}
                                        {data.subcategory.category.name}
                                    </Typography>
                                </Box>
                                <Box component={"span"}>
                                    <Typography style={{ fontSize: 14 }} color="text.primary">
                                        {" "}
                                        {data.subcategory.name}
                                    </Typography>
                                </Box>
                            </Breadcrumbs>

                            <SubcategoryList subcategories={data.subcategory ? [data.subcategory] : []}></SubcategoryList>
                        </Box>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default SubcategoryTemplate;

export const query = graphql`
    query ($slug: String!, $language: String!, $locale: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        subcategory: strapiSubcategory(slug: { eq: $slug }, locale: { eq: $locale }) {
            id
            name
            slug
            locale
            order
            articles {
                id
                title
                slug
                order
                locale
            }
            category {
                id
                name
                slug
                order
                locale
                image {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        subcategories: allStrapiSubcategory(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    name
                    slug
                    order
                    locale
                    articles {
                        id
                        title
                        slug
                        order
                        locale
                    }
                    category {
                        id
                        name
                        slug
                        order
                        locale
                    }
                }
            }
        }
    }
`;
